import Step1 from "./Step1/Step1";
import Step2 from "./Step2/Step2";
import Step3 from "./Step3/Step3";
import StickyBox from "react-sticky-box";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import TotalDetails from "../../../components/Preventivo/TotalBox/TotalDetails/TotalDetails";
import PreventivoSteps from "../../../components/Preventivo/PreventivoSteps/PreventivoSteps";
import usePreventivoData from "../../../hooks/usePreventivoData";
import PreventivoActions from "../../../components/Preventivo/PreventivoActions/PreventivoActions";
import PreventivoModalAlert from "../../../components/Preventivo/PreventivoModalAlert/PreventivoModalAlert";
import { User } from "../../../features/users/UsersSlice";
import { modals } from "@mantine/modals";
import { Container } from "@mantine/core";
import { Preventivo } from "../../../queries/preventivi";
import { formatListino } from "../../../utils/formatters";
import { notifications } from "@mantine/notifications";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectActiveStep, setActiveStep } from "../../../features/ui/uiSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  encodePreventivoString,
  getTemplateString,
} from "../../../utils/utils";
import {
  EPreventivoStatus,
  setInitialPreventivo,
  setVeicolo,
} from "../../../features/preventivo/preventivoSlice";
import {
  useGetTestisQuery,
  useLazyGetListiniForModelloQuery,
  useLazyGetVeicoloQuery,
  usePutNotificationMutation,
  usePutPreventivoMutation,
} from "../../../features/api/apiSlice";

const NewPreventivo = () => {
  const authUser: User | null = useAuthUser();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [putPreventivo, result] = usePutPreventivoMutation();
  const { data: testi } = useGetTestisQuery({
    limit: 10000,
  });
  const [getVeicolo] = useLazyGetVeicoloQuery();
  const [getListiniForModello] = useLazyGetListiniForModelloQuery();
  const [putNotification] = usePutNotificationMutation();
  const activeStep = useAppSelector(selectActiveStep);

  let { preventivoId } = useParams();

  const {
    currentCliente,
    model,
    listino,
    optionals,
    priceFinal,
    preventivo,
    permuteTotal,
    permute,
    servizi,
    accessori,
    incentivi,
    engine,
    veicolo,
    sconti,
    data_validita,
  } = usePreventivoData({ preventivoId });
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);

  const isSavingEnabled = !!(
    currentCliente &&
    model &&
    listino &&
    engine &&
    veicolo?.id
  );

  const saveLabel = !preventivoId
    ? "Salva "
    : location.pathname === `/preventivi/duplica/${preventivoId}`
      ? "Duplica"
      : "Salva";

  const handleSavePreventivo = () => {
    const payload: Preventivo = {
      ...preventivo,
      modello: encodePreventivoString(
        getTemplateString("MODELLO", preventivo?.veicolo, testi?.testis ?? []),
      ),
      oggetto: encodePreventivoString(
        getTemplateString("OGGETTO", preventivo?.veicolo, testi?.testis ?? []),
      ),
      motore: encodePreventivoString(
        getTemplateString("MOTORE", preventivo?.veicolo, testi?.testis ?? []),
      ),
      dimensioni: encodePreventivoString(
        getTemplateString(
          "DIMENSIONI",
          preventivo?.veicolo,
          testi?.testis ?? [],
        ),
      ),
      dotazioni_guida: encodePreventivoString(
        getTemplateString(
          "DOTAZIONI_ASSISTENZA_ALLA_GUIDA",
          preventivo?.veicolo,
          testi?.testis ?? [],
        ),
      ),
      dotazioni_interne: encodePreventivoString(
        getTemplateString(
          "DOTAZIONI_INTERNE",
          preventivo?.veicolo,
          testi?.testis ?? [],
        ),
      ),
      dotazioni_sicurezza: encodePreventivoString(
        getTemplateString(
          "DOTAZIONI_SICUREZZA",
          preventivo?.veicolo,
          testi?.testis ?? [],
        ),
      ),
      dotazioni_tecnologia: encodePreventivoString(
        getTemplateString(
          "DOTAZIONI_TECNOLOGIA",
          preventivo?.veicolo,
          testi?.testis ?? [],
        ),
      ),
      caratteristiche_versione: encodePreventivoString(
        getTemplateString(
          "CARATTERISTICHE_VERSIONE",
          preventivo?.veicolo,
          testi?.testis ?? [],
        ),
      ),
      agente: {
        //@ts-ignore
        ...authUser.custom_data,
        //@ts-ignore
        _id: authUser._id,
        //@ts-ignore
        email: authUser.data.email,
      },
      status: EPreventivoStatus.DRAFT_SAVED,
      price_total: priceFinal,
    };

    setIsSaveLoading(true);

    putPreventivo(payload)
      .unwrap()
      .then((response) => {
        dispatch(setInitialPreventivo(payload));
        console.log(payload);

        notifications.show({
          withBorder: true,
          withCloseButton: true,
          autoClose: 4000,
          position: "top-center",
          title: "Successo!",
          message: !preventivoId
            ? "Preventivo creato con successo."
            : location.pathname === `/preventivi/duplica/${preventivoId}`
              ? "Preventivo duplicato con successo."
              : "Preventivo modificato con successo.",
          color: "teal",
          radius: "md",
          loading: false,
        });

        setIsSaveLoading(false);

        putNotification({
          //@ts-ignore
          id_agente_from: authUser._id,
          //@ts-ignore
          nome_agente_from: authUser.custom_data.name,
          //@ts-ignore
          id_agente_to: authUser._id,
          //@ts-ignore
          nome_agente_to: authUser.custom_data.name,
          //@ts-ignore
          id_preventivo: response.upsertOnePreventivi._id,
          text: "Test notifica automatica",
        });

        navigate(
          //@ts-ignore -- non cè modo di fare casting di response
          `/preventivi/visualizza/${response.upsertOnePreventivi._id}`,
        );
      })
      .catch((error) => {
        console.error("rejected", error);
        notifications.show({
          withCloseButton: true,
          position: "top-center",
          title: "Errore",
          radius: "md",
          message: !preventivoId
            ? "Errore nel salvataggio del preventivo. Riprova."
            : location.pathname === `/preventivi/duplica/${preventivoId}`
              ? "Errore nella duplicazione del preventivo. Riprova."
              : "Errore nella modifica del preventivo. Riprova.",
          color: "pink",
          loading: false,
        });
        setIsSaveLoading(false);
      });
  };

  const step1Ref = useRef<HTMLDivElement>(null);
  const step2Ref = useRef<HTMLDivElement>(null);
  const step3Ref = useRef<HTMLDivElement>(null);

  // Effetto per monitorare lo scroll
  useEffect(() => {
    const handleScroll = () => {
      const stepOffsets = [
        { step: 1, offset: step1Ref.current?.getBoundingClientRect().top },
        { step: 2, offset: step2Ref.current?.getBoundingClientRect().top },
        { step: 3, offset: step3Ref.current?.getBoundingClientRect().top },
      ];
      stepOffsets.forEach(({ step, offset }) => {
        if (
          offset !== undefined &&
          offset >= 0 &&
          offset <= window.innerHeight / 2
        ) {
          if (activeStep !== step) {
            dispatch(setActiveStep(step));
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dispatch, activeStep]);

  const scrollToStep = (step: number) => {
    const element =
      step === 1
        ? step1Ref.current
        : step === 2
          ? step2Ref.current
          : step3Ref.current;

    if (element) {
      const offset = 164;
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({ top: elementPosition, behavior: "smooth" });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setActiveStep(1));
  }, []);

  const fetchVeicolo = async () => {
    try {
      const veicoloQuery = await getVeicolo({
        id: veicolo?.id || "",
        listino: veicolo?.listino || "",
      }).unwrap();

      const isVeicoloMarginDefined = veicolo?.margine_base;
      const isVeicoloQueryMarginDefined = veicoloQuery.veicoli.margine_base;
      //console.log(isVeicoloMarginDefined, isVeicoloQueryMarginDefined)

      if (
        isVeicoloMarginDefined &&
        isVeicoloQueryMarginDefined &&
        veicoloQuery.veicoli.margine_base !== veicolo?.margine_base
      ) {
        dispatch(
          setVeicolo({
            ...veicolo,
            margine_base: veicoloQuery?.veicoli.margine_base || 0,
          }),
        );

        modals.open({
          overlayProps: {
            backgroundOpacity: 0.55,
            blur: 3,
          },
          centered: true,
          withCloseButton: true,
          shadow: "md",
          radius: 26,
          children: (
            <PreventivoModalAlert type="error">
              <>
                <p className="mx-auto mb-3 max-w-72 text-center font-semibold leading-5">
                  Il margine base sul veicolo selezionato è cambiato.
                </p>
                <p className="mx-auto max-w-72 text-center text-sm font-light leading-5">
                  Vecchio margine:{" "}
                  <span className="font-medium text-slate-700">
                    {veicolo?.margine_base}%
                  </span>
                </p>
                <p className="mx-auto mb-6 max-w-72 text-center text-sm font-light leading-5">
                  Nuovo margine:{" "}
                  <span className="font-medium text-slate-700">
                    {veicoloQuery.veicoli.margine_base}%
                  </span>
                </p>
                <p className="mx-auto text-center text-sm">
                  Il nuovo margine è stato automaticamente aggiornato.
                </p>
              </>
            </PreventivoModalAlert>
          ),
        });
      }
    } catch (error) {
      console.error("Error fetching veicolo data:", error);
    }
  };

  const fetchListiniForModello = async () => {
    try {
      const listiniForModelloQuery = await getListiniForModello({
        model: model,
      }).unwrap();

      const latestListino =
        listiniForModelloQuery.listini[
          listiniForModelloQuery.listini.length - 1
        ];

      if (latestListino !== listino) {
        modals.open({
          overlayProps: {
            backgroundOpacity: 0.55,
            blur: 3,
          },
          centered: true,
          withCloseButton: true,
          shadow: "md",
          radius: 26,
          children: (
            <PreventivoModalAlert type="warn">
              <>
                <p className="mx-auto mb-3 max-w-72 text-center font-semibold leading-5">
                  Per il veicolo selezionato è disponibile un listino più
                  aggiornato.
                </p>
                <p className="mx-auto max-w-72 text-center text-sm font-light leading-5">
                  Listino selezionato:{" "}
                  <span className="font-medium text-slate-700">
                    {formatListino(listino || "")}
                  </span>
                </p>
                <p className="mx-auto max-w-72 text-center text-sm font-light leading-5">
                  Nuovo listino:{" "}
                  <span className="font-medium text-slate-700">
                    {formatListino(latestListino)}
                  </span>
                </p>
              </>
            </PreventivoModalAlert>
          ),
        });
      }
    } catch (error) {
      console.error("Error fetching listino data:", error);
    }
  };

  useEffect(() => {
    if (preventivoId) {
      setTimeout(() => {
        fetchVeicolo();
        fetchListiniForModello();
      }, 501);
    }
  }, [model, listino]);

  return (
    <>
      <PreventivoSteps scrollToStep={scrollToStep} />

      <PreventivoActions
        preventivoId={preventivoId || ""}
        saveLabel={saveLabel}
        isSaveLoading={isSaveLoading}
        isSavingEnabled={isSavingEnabled}
        handleSavePreventivo={handleSavePreventivo}
      />
      <Container
        size="100%"
        px="40"
        py="40"
        className="flex items-start gap-11 bg-white"
      >
        <div className="w-full">
          <div ref={step1Ref}>
            <Step1
              model={model}
              veicolo={veicolo}
              engine={engine}
              listino={listino}
            />
          </div>

          <div ref={step2Ref}>
            <Step2 />
          </div>
          <div ref={step3Ref}>
            <Step3
              veicolo={veicolo}
              optionals={optionals}
              accessori={accessori}
              servizi={servizi}
              incentivi={incentivi}
              permuteTotal={permuteTotal}
              permute={permute}
              sconti={sconti}
              //sconto={scontoGrandTotal}
              //scontoGrandTotal={scontoGrandTotal}
            />
          </div>
          <PreventivoActions
            position="bottom"
            saveLabel={saveLabel}
            isSaveLoading={isSaveLoading}
            isSavingEnabled={isSavingEnabled}
            preventivoId={preventivoId || ""}
            handleSavePreventivo={handleSavePreventivo}
          />
        </div>

        <StickyBox offsetTop={144} offsetBottom={20}>
          <TotalDetails />
        </StickyBox>
      </Container>
    </>
  );
};

export default NewPreventivo;
