import { useAppSelector } from "../../../app/hooks";
import { selectActiveStep } from "../../../features/ui/uiSlice";

interface PreventivoStepsProps {
  scrollToStep: (step: number) => void;
}

const PreventivoSteps = ({ scrollToStep }: PreventivoStepsProps) => {
  const activeStep = useAppSelector(selectActiveStep);
  const steps = [
    { id: 1, label: "Veicolo base" },
    {
      id: 2,
      label: "Opzioni e servizi",
    },
    {
      id: 3,
      label: "Sconti e pagamenti",
    },
  ];

  const preventivoStepsClasses = {
    active:
      "flex items-end gap-2 cursor-pointer relative text-sm text-cm-blue font-semibold pb-2 before:w-[calc(100%+12px)] before:h-[3px] before:bg-cm-blue before:absolute before:-left-[6px] before:bottom-0 before:rounded-full",
    idle: "flex items-end gap-2 cursor-pointer text-sm text-slate-600 pb-2",
  };

  const handleStepClick = (id: number) => {
    scrollToStep(id);
  };

  return (
    <div className="sticky top-0 bg-[#f4f4f4] z-50">
      <ul className="flex justify-center gap-20 bg-white pt-6 shadow-md">
        {steps.map((step) => (
          <li
            key={step.id}
            className={
              activeStep === step.id
                ? preventivoStepsClasses.active
                : preventivoStepsClasses.idle
            }
            onClick={() => handleStepClick(step.id)}
          >
            {step.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PreventivoSteps;
