import OptionRow from "../../Option/OptionRow";
import SummaryBoxLabel from "../SummaryBox/SummaryBoxLabel";
import { List } from "@mantine/core";
import { EOptionType, Option } from "../../../queries/veicoli";
import { IconCircleCheckFilled } from "@tabler/icons-react";

export interface OptionalsSummary {
  type: EOptionType;
  items: Option[];
  isReadOnly?: boolean;
  isOptionReadOnly?: boolean;
  isInputShown: {
    id: string | null;
    state: boolean;
  };
}

const OptionalsSummary = ({
  type,
  items,
  isReadOnly,
  isOptionReadOnly,
}: {
  type: EOptionType;
  items: Option[];
  isReadOnly?: boolean;
  isOptionReadOnly?: boolean;
}) => {
  const totalItems = items ? items.length : 0;
  const isAftermarket = type === EOptionType.ACCESSORI;

  return totalItems > 0 ? (
    <>
      {!isReadOnly && <SummaryBoxLabel text="Riepilogo" />}
      <div className="summary__list">
        <List
          spacing="xxs"
          size="sm"
          center
          icon={<IconCircleCheckFilled className="text-cm-blue" />}
        >
          <OptionRow
            type={type}
            items={items}
            isAftermarket={isAftermarket}
            isReadOnly={isReadOnly}
            isOptionReadOnly={isOptionReadOnly}
          />
        </List>
      </div>
    </>
  ) : (
    <p className="text-sm font-light">Nessun elemento presente.</p>
  );
};

export default OptionalsSummary;
